



















import Vue from 'vue'
import CatalogInstancesList from '@/views/components/catalog/CatalogInstancesList.vue'

export default Vue.extend({
  components: {
    CatalogInstancesList,
  },
  data() {
    return {
      catalogInstances: {
        columns: [{ name: 'instanceName' }, { name: 'instanceId' }, { name: 'catalogName' }, { name: 'catalogId' }],
      },
    }
  },
  methods: {
    redirectToAddPage() {
      this.$router.push({ name: 'catalogInstanceAdd' }).catch(() => {
      })
    },
  },
})
