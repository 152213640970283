<template>
  <div>
    <b-overlay :show="dataLoading" spinner-variant="primary" variants="white" opacity="0.6">
      <vs-col v-for="(instance, index) in instances" :key="index" vs-w="12">
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title>
                {{ instance.name }}
              </b-card-title>
              <b-card-text class="mb-0">
                <b-badge v-if="!instance.production" pill variant="light-danger">
                  {{ $t("catalog.instance.workingDirectory") }}
                </b-badge>
                {{ instance.description }}
              </b-card-text>
            </div>
            <b-dropdown
              variant="link"
              no-caret
              right
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item
                @click="goToCatalogDetails(instance.id)"
              >
                {{ $t("catalog.instance.showDetails") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isProduction(instance)"
                @click="goToEditCatalogMapping(instance.id)"
              >
                {{ $t("catalog.instance.editMapping") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isProduction(instance)"
                @click="goToEditTimeRefresh(instance.id)"
              >
                {{ $t("catalog.instance.editTimeRefresh") }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="goToCatalogHistory(instance.id)"
              >
                {{ $t("catalog.instance.history") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isProduction(instance)"
                @click="goToCatalogCategoriesMapping(instance.id)"
              >
                {{ $t("catalog.instance.categoryMappings") }}
              </b-dropdown-item>
              <b-dropdown-item
                :class="{deleteItem: true, disabled: !canBeDeleted(instance)}"
                @click="deleteCatalog(instance)"
              >
                {{ $t("catalog.instance.delete") }}
                <vs-tooltip v-if="!canBeDeleted(instance)" :text="getDeletePopupTranslation(instance)" class="float-right">
                  <alert-circle-icon />
                </vs-tooltip>
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("catalog.instance.header.id") }}
              </dt>
              <dd class="col-sm-9">
                {{ instance.id }}
              </dd>
            </dl>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("catalog.instance.header.name") }}
              </dt>
              <dd class="col-sm-9">
                {{ instance.name }}
              </dd>
            </dl>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("catalog.instance.header.url") }}
              </dt>
              <dd class="col-sm-9">
                {{ instance.url }}
              </dd>
            </dl>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("catalog.instance.header.language") }}
              </dt>
              <dd class="col-sm-9">
                {{ $t('language.' + instance.language) }}
              </dd>
            </dl>
            <dl class="row mb-1 mb-md-0">
              <dt class="col-sm-3">
                {{ $t("catalog.instance.header.industry") }}
              </dt>
              <dd class="col-sm-9">
                {{ $t('industry.' + instance.industry) }}
              </dd>
            </dl>
          </b-card-body>
        </b-card>
      </vs-col>
    </b-overlay>
  </div>
</template>

<script>
import CatalogInstancesStore from '@/store/catalog/catalogInstances'
import { AlertCircleIcon } from 'vue-feather-icons'

export default {
  components: {
    AlertCircleIcon,
  },
  computed: {
    instances() {
      return CatalogInstancesStore.state.instances
    },
    dataLoading() {
      return CatalogInstancesStore.state.dataLoading
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    canBeDeleted(item) {
      return item.isDeletable && item.production
    },
    isProduction(item) {
      return item.production
    },
    getData() {
      return CatalogInstancesStore.dispatch('geCatalogInstancesData')
    },
    goToCatalogDetails(catalogId) {
      this.$router.push({ name: 'catalogInstance', params: { catalogId } })
    },
    goToEditCatalogMapping(catalogId) {
      this.$router.push({ name: 'catalogMapping', params: { catalogId } })
    },
    goToCatalogHistory(catalogId) {
      this.$router.push({ name: 'catalogHistory', params: { catalogId } })
    },
    goToEditTimeRefresh(catalogId) {
      this.$router.push({ name: 'catalogTime', params: { catalogId } })
    },
    goToCatalogCategoriesMapping(catalogId) {
      this.$router.push({ name: 'catalogInstanceCategories', params: { catalogId } })
    },
    deleteCatalog(catalog) {
      return this.canBeDeleted(catalog) ? CatalogInstancesStore.dispatch('deleteCatalogInstance', catalog.id) : null
    },
    getDeletePopupTranslation(item) {
      if (!item.production) {
        return this.$t('catalog.instance.deletetips.notproduction')
      }
      if (!item.isDeletable) {
        return this.$t('catalog.instance.deletetips.assignedSearch')
      }
      return ''
    },
  },
}
</script>

<style>
  .deleteItem.disabled .dropdown-item{
    color: #b9b9c3 !important;
  }
</style>
