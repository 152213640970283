import Vue from 'vue'
import Vuex from 'vuex'

import { CatalogInstances } from '@/store/catalog/catalogApiRequestHelper'
import CatalogApiRequest, { CatalogInstance } from '@/store/catalog/catalogApiRequest'
import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'

Vue.use(Vuex)

const getInitialState = (): CatalogInstances => ({
  dataLoading: true,
  instances: [],
})

export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setCatalogsInstancesList(state: CatalogInstances, data: CatalogInstances) {
      state.instances = data.instances
      state.dataLoading = data.dataLoading
    },
  },
  actions: {
    geCatalogInstancesData(context: any) {
      const instances = CatalogApiRequest.getCatalogInstances()

      return instances
        .then((result: AxiosResponse<Array<CatalogInstance>>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances', { root: true })
            return
          }
          context.commit('setCatalogsInstancesList', { instances: result.data, dataLoading: false })
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },
    deleteCatalogInstance(context: any, catalogId: string) {
      CatalogApiRequest.deleteCatalog(catalogId).then(() => {
        context.dispatch('geCatalogInstancesData')
      }).catch(error => {
        Notifications.commit('addError', error.response.data.msg ?? 'search.errorGettingSearchInstances', { root: true })
      })
    },
  },
})
